import React, {useEffect , useState} from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {
    ThemeProvider,
    createTheme,
    experimental_sx as sx,
} from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import {Container} from "@mui/material";
import {grey} from "@mui/material/colors";


const theme = createTheme({
    components: {
        // Name of the component ⚛️
        MuiTab: {
            styleOverrides: {
                root: sx({
                    '&.Mui-selected':{

                        borderTopLeftRadius: '4px' ,
                        borderTopRightRadius: '4px' ,
                        border: `2px solid #1976d2`,
                        color: 'white' ,
                        background: '#0072e5'

                    }
                })
            },
        },
    },
});

// возвращает верхнию и нижнию координату элементов по их ID переданных в объекте items
const getCoordinats = (items , prefix='h-') => {

    const clientH = document.documentElement.clientHeight;
    const scrollH = document.documentElement.scrollHeight;
    const maxY = scrollH - clientH;

    let result=new Array();

    items.map((item , idx) => {
        if(item.hasOwnProperty("id")){
            let visible = true;
            const block = document.querySelector(
                `#${prefix}${item.id}`);
            //console.log("h-5571484" , block.getBoundingClientRect());
            let bounding = block.getBoundingClientRect();
            const offsetY = 114;
            if(bounding.top > maxY)visible=false;
            result[idx] = {element: item.id , top: bounding.top - offsetY , bottom: bounding.bottom - offsetY , visible: visible};
        }
    });

    return result;
}

// контролирует пересечение точки ТОП нужным элементом
const elementCrossTop = (elementCoordList = [] , currentY) => {
    let cross = elementCoordList.find((el) => Math.round(el.top) <= currentY && Math.round(el.bottom) >= currentY);
    return cross;
}

const SliderTabBar = ({items}) => {

    // определить первый элемент для ТАБОВ
    //console.log(items);
    const [showTab , setShowTab] = useState(true);

    // если массив items пустой не отображать табы
// console.log("items" , items);

    // const uid = Array.isArray(items) && ("id" in items[0]) ? items[0].id : null ;
    const [value, setValue] = useState(null); // первый элемент

    useEffect(() => {
        // console.log("DISPLAY" , document.documentElement.clientHeight);
        // console.log("SCROLL" , document.documentElement.scrollHeight);
        let uid = null;
        if(Array.isArray(items) ){
            const _uid = typeof items[0] !== 'undefined' ? items[0] : null;
            if(_uid)uid = _uid.hasOwnProperty("id") ? _uid.id : null ;
        }
        setValue(uid);
        if(Array.isArray(items) && items.length <= 1)setShowTab(false);

        const handleScroll = (event , arCoord) => {
            let winY = window.scrollY;
            // console.log(winY);
            // console.log("arCoord" , arCoord);
            let cross = elementCrossTop(arCoord , winY);
            if(cross && cross.visible)setValue(cross.element);
        };

        const coordList = getCoordinats(items);
        window.addEventListener('scroll', (e) => handleScroll(e , coordList));

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleChange = (event, newValue) => {
       //console.log(newValue);
        setValue(newValue);
    };

    const handleClick = (event , id) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            `#h-${id}`,
        );

        if (anchor) {
            const yOffset = -98;

            const y = anchor.getBoundingClientRect().top + window.scrollY + yOffset;

            window.scrollTo({top: y, behavior: 'smooth'});
            //anchor.scrollIntoView({block: "start", behavior: "smooth" , inline: "nearest"});
        }
    }

    if(!showTab)return null;

    return (
        <Container sx={{position: 'fixed' , top: '48px' , zIndex: '1000' ,
            background: 'white' ,
            borderBottom: '1px solid rgb(0 0 0 / 12%)' ,
            paddingLeft: '26px' ,
            paddingRight: '26px' ,
            backgroundColor: grey['A100']
        }}>
        {/*<ThemeProvider theme={theme}>*/}
            {/*<AppBar position="sticky" color='inherit' sx={{top: 48}} elevation={0}>*/}

                    <Tabs

                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons={true}
                        allowScrollButtonsMobile
                        aria-label="visible arrows tabs example"
                        sx={{
                            [`& .${tabsClasses.scrollButtons}`]: {
                                '&.Mui-disabled': { opacity: 0.3 },
                                '&.MuiTabScrollButton-horizontal': {width: '20px'}

                            },
                        }}
                    >
                        {
                            items.map((item) => {

                                return(
                                    <>
                                        {item.hasOwnProperty("id") &&
                                            <Tab key={item.id} sx={{padding: '0px 12px 0px 12px' }}
                                                 label={
                                                     <React.Fragment>
                                                         <Typography variant="button" sx={{fontSize: '0.750rem'}}>{item.title}</Typography>
                                                     </React.Fragment>
                                                 }
                                                 value={item.id}
                                                 onClick={(e)=> handleClick(e ,item.id)}
                                            />
                                        }
                                    </>
                                );

                            })
                        }


                    </Tabs>

            {/*</AppBar>*/}

        {/*</ThemeProvider>*/}
        </Container>
    );
};

export default SliderTabBar;

