import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { dateFormater } from '../../utils/date-formater';
import { isObjEmpty } from '../../utils';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import SnackbarNotify from "../snackbar-notify";

// const tg = window.Telegram.WebApp;

// Проверка на включенный сторадж
let storageSupported = false;
try {
    storageSupported = (window.localStorage && true);
} catch (e) {}

const FinalDisplay = ({ infoCart, reduxCartClear , snackBarClose , tg }) => {
    const activity = useSelector((state) => state.activity);

    const { serviceCartItems, employeeCartItems, orderTotal, seanceDate } = infoCart;
    const master = employeeCartItems.master;
    let strSeanceTime = '';
    let strSeanse = '';

    const onClickTgButton = () => {
        tg.close();
    };

    tg.MainButton.onClick(onClickTgButton);

    useEffect(() => {
        if (storageSupported) {
            localStorage.clear();
        }
        reduxCartClear();
        tg.MainButton.text = 'Закрыть';
        tg.MainButton.show();
    }, []);

    if (!isObjEmpty(seanceDate)) {
        const sDate = dateFormater(seanceDate.date, 'DAY_MON');
        const seanceWeekDay = dateFormater(seanceDate.date, 'LONGDAY');
        strSeanceTime = `${seanceWeekDay} в ${seanceDate.time}`;
        strSeanse = `${sDate} , ${strSeanceTime}`;
    }

    return (
        <Box
            sx={{
                width: '100%',
                bgcolor: 'background.paper',

                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <SnackbarNotify variant="success" boolOpen={true} message={"Вы успешно записаны"} setClose={snackBarClose}/>

            <Box sx={{ my: 3, mx: 2 }}>
                <Grid container alignItems='center'>
                    <Grid item xs></Grid>
                    <Grid item>
                        <Typography gutterBottom variant='h5' component='div'>
                            Вы записаны {activity.id && 'на групповое занятие'}
                        </Typography>
                    </Grid>
                    <Grid item xs></Grid>
                </Grid>

                {activity.id ? (
                    <>
                        <Typography color='text.secondary' variant='body2'>
                            Название: {activity.service.title}
                        </Typography>
                        <Divider sx={{ borderStyle: 'dashed', margin: '5px' }} />
                        <Typography color='text.secondary' variant='body2'>
                            Тренер: {activity.staff.name}
                        </Typography>
                        <Divider sx={{ borderStyle: 'dashed', margin: '5px' }} />
                        <Typography color='text.secondary' variant='body2'>
                            Дата: {activity.date_activity}
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography color='text.secondary' variant='body2'>
                            {strSeanse}
                        </Typography>
                        <Divider sx={{ borderStyle: 'dashed', margin: '5px' }} />
                        <Typography color='text.secondary' variant='body2'>
                            к мастеру: {master}
                        </Typography>

                        <ul sx={{ listStyleType: 'none' }}>
                            {serviceCartItems.map((item) => {
                                return (
                                    <li>
                                        <Grid container alignItems='center'>
                                            <Grid item xs>
                                                <Typography color='text.secondary' variant='body2'>
                                                    {item.title}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography color='text.secondary' variant='body2'>
                                                    {item.total} руб.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </li>
                                );
                            })}
                        </ul>
                    </>
                )}
            </Box>
            <Divider variant='middle' sx={{ borderStyle: 'dashed' }} />
            <Box sx={{ m: 2 }}>
                <Grid container alignItems='center'>
                    <Grid item xs>
                        <Typography gutterBottom variant='h5' component='div'>
                            Итого:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography gutterBottom variant='h6' component='div'>
                            {orderTotal} руб.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default FinalDisplay;
