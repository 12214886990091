import React, {Component , useEffect} from "react";
import List from "@mui/material/List";
import ListSubheader from '@mui/material/ListSubheader';
import ServiceListItem from "../service-list-item/service-list-item";
import {connect} from "react-redux";
import SkeletonServicesList from "../skeletons/skeleton-services-list";
import {withClientstoreService} from "../hoc";
import {fetchServices , serviceAddedToCart } from "../../actions";
import ErrorIndicator from "../error-indicator";
import SliderTabBar from "../slider-tab-bar";
import Box from '@mui/material/Box';

// import Typography from '@mui/material/Typography';

import Divider from '@mui/material/Divider';
import ListItem from "@mui/material/ListItem";
import Alert from "@mui/material/Alert";
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import ListItemAvatar from "@mui/material/ListItemAvatar";
// import ListItemText from "@mui/material/ListItemText";
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import Button from '@mui/material/Button';
// import AddIcon from '@mui/icons-material/Add';
// import IconButton from '@mui/material/IconButton';
// import Chip from '@mui/material/Chip';
// import {dataFilter} from "../../utils/data-filter";
// import {Link} from "react-router-dom";
import ServicesFooterBar from "../slider-tab-bar/services-footer-bar";
import './service-list.css';


const ServiceList = ({cartItems , services , onAddedToCart , category}) => {

    // const fabStyle = {
    //     bottom: 10,
    //     position: 'fixed',
    //     width: '98%'
    // };


 return(
        <Box >
        <List  sx={{
            width: '100%',

            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',

            '& ul': { padding: 0 },
        }}

            subheader={<li />}>
            {
                category.map((item) => {
                  return(
                      <>
                          {item.hasOwnProperty("id") &&
                              <li key={item.id}>
                                  <ul>
                                      <ListSubheader id={`h-${item.id}`} color='inherit' >{item.title}</ListSubheader>
                                      {
                                          services.map((service) => {
                                              // if(service.active > 0){
                                              if(service.category_id === item.id){
                                                  let checked = false;
                                                  if(cartItems.find((el => el.id === service.id)) !== undefined){
                                                      checked = true;
                                                  }

                                                  return(
                                                      <React.Fragment>
                                                          <ListItem  key={service.id} sx={{ paddingLeft: '0px' , paddingRight: '0px'}}>
                                                              <ServiceListItem service={service} check={checked} onAddedToCart={() => onAddedToCart(service.id)} />
                                                          </ListItem>
                                                          <Divider variant="inset"  />

                                                      </React.Fragment>
                                                  )
                                              }
                                              // }
                                          })
                                      }
                                  </ul>
                              </li>
                          }
                      </>

                    )
                })
            }
        </List>
        </Box>
     );

}

const ServiceListContainer =({fetchServices ,
                                 serviceCartItems ,
                                 orderTotal ,
                                 services ,
                                 loading , error ,
                                 onAddedToCart , employeeCartItems , seanceDate,
                             update , term , category , bookform , companyId , tgid}) => {

    const empty = Array.isArray(services) && services.length === 0 ;

    const emptyContent = (
        <>
            <Alert sx={{m:1}}>
                Нет доступных услуг
            </Alert>
        </>
    );

    useEffect(() => {
        //console.log("BOOKFORM" ,bookform);
        const propsEmpId = employeeCartItems.id;
        const propsDateTime = seanceDate;
        //const companyId = bookform.data.company_id;
        const objFilter = {
            id: propsEmpId  ,
            datetime: propsDateTime ,
            services: serviceCartItems ,
            term ,
            company_id: companyId ,
            tgid
        };

        fetchServices(objFilter);
    } , [update , serviceCartItems , companyId]);

    let showFooterBar = true;
   // const {serviceCartItems , orderTotal , services , loading , error , onAddedToCart  } = props;

        if(empty){
            return (<>{emptyContent}</>)
        }
        if(loading){
            return <SkeletonServicesList />
        }

        if(error){
            console.log(error);
            return <ErrorIndicator error={error}/>
        }

        let sCount = serviceCartItems.length;
            if(sCount === 0){
                showFooterBar = false;
            }

        return(
            <Box  >
                <SliderTabBar items={category}/>

                <ServiceList services={services} category={category} cartItems={serviceCartItems} onAddedToCart={onAddedToCart} />

                <ServicesFooterBar show={showFooterBar} total={orderTotal} count={sCount} />
            </Box>

        );


}

const mapStateToProps = ({  bookform ,servicesList: {services , loading , error } ,
                             serviceCart: { serviceCartItems , employeeCartItems , orderTotal , seanceDate} ,
                             term:{term , update} , tguserdata

                         }) => {
    // console.log('MAP STATE' , bookform);
    return {
        services: services.services ,
        category: services.category ,
        companyId: bookform.bookform.data.company_id ,
        bookform: bookform,
        loading ,
        error ,
        serviceCartItems ,
        employeeCartItems ,
        seanceDate ,
        orderTotal ,
        term , update ,
        tgid: tguserdata.user ? tguserdata.user.id : "" ,
    }
}

const mapDispatchToProps = (dispatch , {clientstoreService}) => {

    //const  = ownProps;
    return {
        fetchServices : fetchServices(clientstoreService , dispatch ) ,
        onAddedToCart:  (id) => dispatch(serviceAddedToCart(id)) ,
        _getTabDate: clientstoreService._getTabDate
    }
}


export default withClientstoreService()(connect(mapStateToProps , mapDispatchToProps)(ServiceListContainer));

// services.map((service) => {
//     //  if(service.category_id == categItem.id){
//     let checked = false;
//     if(cartItems.find((el => el.id === service.id)) !== undefined){
//         checked = true;
//     }
//
//     return(
//         <React.Fragment>
//
//             <ListItem  key={service.id} >
//                 <ServiceListItem service={service} check={checked} onAddedToCart={() => onAddedToCart(service.id)} />
//             </ListItem>
//             <Divider variant="inset" component="li" />
//         </React.Fragment>
//     )
//
//     //  }
// })