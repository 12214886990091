import React , {Component , useEffect} from "react";
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';

import Typography from '@mui/material/Typography';

import {fetchBookTimes, fetchBookDates, setCurrentBookDate, employeeAddedToCart} from "../../actions";
import TimetablePanel from "./timetable-panel";
import {dataFilter , createArrFromObjKeyId , strApiParamForFilter} from "../../utils/data-filter";


import {
    ThemeProvider,
    createTheme,
    experimental_sx as sx,
} from '@mui/material/styles';
import {withClientstoreService} from "../hoc";
import {connect} from "react-redux";
import MySpinner from "../spinner";
import ErrorIndicator from "../error-indicator";

const theme = createTheme({
    components: {
        // Name of the component ⚛️
        MuiTab: {
            styleOverrides: {
                root: sx({
                    '&.Mui-selected':{

                        borderTopLeftRadius: '4px' ,
                        borderTopRightRadius: '4px' ,
                        border: `2px solid #1976d2`,
                        color: 'white' ,
                        background: '#0072e5'

                    }
                })
            },
        },
    },
});

const Timetable = ({dates ,
                       setCurrentDate ,
                       currentdate ,
                       formatDate ,
                       fetchBookTimes ,
                       booktimes ,
                       loading ,
                       error ,
                       addToCart , serviceCartItems ,
                       curEID , company_id , tgid}) => {

    const booking_dates = dates.booking_dates;
    let vNow = formatDate('0000' , 'STD' );

    if(Array.isArray(booking_dates) ){
        vNow = booking_dates[0];
        //console.log(" IFELSE " , vNow );
    }

    // const servicesIds = serviceCartItems.map(el => el.id);


    useEffect(() => {
        // готовим фильтр
        const objFilter = {
            eId: curEID,
            date: currentdate,
            services: serviceCartItems,
            company_id ,
            tgid ,
        };

        // fetchBookTimes({ eId: curEID,date: currentdate , company_id });
        // console.log("useEffect" , servicesIds);
        fetchBookTimes(objFilter);

    } , [currentdate]);

    const [value, setValue] = React.useState(vNow);

    const handleChange = (event, newValue) => {
        console.log(" change ",newValue);
        setValue(newValue);
        setCurrentDate(newValue);
    };

    return(
        <Box
            sx={{
                flexGrow: 1,
                bgcolor: 'background.paper',
                paddingLeft: '5px',
                paddingRight: '5px'
            }}
        >
            <ThemeProvider theme={theme}>

                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons={true}
                    allowScrollButtonsMobile

                    sx={{
                        [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3 },
                            '&.MuiTabScrollButton-horizontal': {width: '18px'}
                        },
                    }}
                >
                    {
                        booking_dates.map((date) => {

                            return(
                                <Tab label={<React.Fragment>
                                    <Typography variant="h6">{formatDate(date , 'nDAY')}</Typography>
                                    <Typography variant="caption">{formatDate(date , 'DAY')}</Typography>
                                </React.Fragment>} value={date}/>

                            )

                        })
                    }


                </Tabs>
            </ThemeProvider>

            <TimetablePanel currentDate={currentdate}
                            formatDate={formatDate}
                            times={booktimes}
                            er={error}
                            loading={loading}
                            addToCart={addToCart}
                            company_id={company_id}
                            emptyService={serviceCartItems.length===0}
            />


        </Box>
    );
}

const TimetableContainer = ({fetchBookDates ,
                                setCurrentDate ,
                                fetchBookTimes ,
                                _getTabDate ,
                                current ,
                                dloading ,
                                derror ,
                                tError,tLoading,
                                bookdates ,booktimes , addedToCart , serviceCart , company_id , tgid}) => {

    // создаём массив с ID  услуг из объекта
    const {employeeCartItems: {id} , serviceCartItems } = serviceCart;

    useEffect(() => {

        // готовим фильтр
        const objFilter = {
            eId: id,
            datetime: '',
            services: serviceCartItems,
            company_id ,
            tgid
        };


        fetchBookDates(objFilter);


    } , []);
    // console.log('BOOKTIMES' , booktimes);

    if(dloading){
        return (<MySpinner />);
    }

    if(derror){
        return <ErrorIndicator />
    }

    return (

        <Timetable dates={bookdates}
                   currentdate={current}
                   setCurrentDate={setCurrentDate}
                   formatDate={_getTabDate}
                   fetchBookTimes={fetchBookTimes}
                   serviceCartItems={serviceCartItems}
                   booktimes={booktimes}
                   loading={tLoading}
                   curEID={id}
                   error={tError} addToCart={addedToCart}
                   company_id={company_id}
                   tgid={tgid}
        />

    );
}



const mapStateToProps = ({ booktimes  , bookdatesList , serviceCart , bookform , tguserdata}) => {
//console.log("bookform.bookform" , bookform.bookform.data.company_id);
    return {
        booktimes : booktimes.booktimes ,
        tLoading: booktimes.loading  ,
        tError: booktimes.error ,
        bookdates: bookdatesList.bookdates ,
        dloading: bookdatesList.loading ,
        derror: bookdatesList.error ,
        current: bookdatesList.current ,
        serviceCart ,
        company_id: bookform.bookform.data.company_id ,
        tgid: tguserdata.user ? tguserdata.user.id : "" ,
    }
}

const mapDispatchToProps = (dispatch , {clientstoreService}) => {

    //const  = ownProps;
    return {
        fetchBookTimes : fetchBookTimes(clientstoreService , dispatch) ,
        fetchBookDates : fetchBookDates(clientstoreService , dispatch) ,
        setCurrentDate: (date) => dispatch(setCurrentBookDate(date)) ,
        addedToCart: (params) => dispatch(employeeAddedToCart(params)),
        _getTabDate: clientstoreService._getTabDate
    }

}

export default withClientstoreService()(connect(mapStateToProps , mapDispatchToProps)(TimetableContainer));