import React , { useEffect , useState } from 'react';
import {connect, useSelector} from "react-redux";
import { useLocation } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import {withClientstoreService} from "../hoc";
import Alert from '@mui/material/Alert';
import { grey } from '@mui/material/colors';
import {alpha} from "@mui/material/styles";
import Stack from '@mui/material/Stack';
import {setUserPhone, requestBookRecord, getSmsCode, checkSmsCode, serviceCartClear} from "../../actions";
import {createArrFromObjKeyId, strDateFormatYC  } from '../../utils/data-filter';
import isObjEmpty from '../../utils/empty-object';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import SnackbarNotify from "../snackbar-notify";



import * as phoneMask from "../../utils/input-mask-phone";
import {setCurrentTerm, updateEmployeeListByTerm} from "../../actions";
import formPhonePage from "../pages/form-phone-page";
import Typography from "@mui/material/Typography";

import MySpinner from "../spinner";
import FullScreenDialog from "../final-display/fullscreen-dialog";
import PreFinalDisplay from "../final-display/pre-final-display";
import {FinalDisplay} from "../final-display";

const tg = window.Telegram.WebApp;
// tg.requestContact((sendSontact) => console.log(sendSontact));

const paperStyle = { p: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%' ,
    borderRadius: 1 ,
    backgroundColor: alpha(grey[700], 0),
    '&:hover': {
        backgroundColor: alpha(grey[700], 0.1),
    }
};

// const PhoneError = ({message}) => {
//     return (
//         <Alert variant="outlined" severity="error">
//             {message}
//         </Alert>
//     );
// }

const phoneFormatCheck = (phone) => {
    if(phone === null) return false;
    let plen = phone.length;
    // if(plen < 17 || plen > 18){
    if(plen < 10){
        return false;
    }
    return true;
}

function tgAlert(message , title="Результат") {
    tg.showPopup({
        title: title, message: message, buttons: [{
            type: "close",
            text: "Закрыть"
        }]
    });
}



const PhoneSubmitButton = ({phone , onclick , type="web" , smsopen = false}) => {

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onclick)
        return () => {
            tg.offEvent('mainButtonClicked', onclick)
        }
    }, [onclick])

    if(type === 'web'){
        return (
            phoneFormatCheck(phone) ?
                <React.Fragment>
                    {/*<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />*/}
                    {/*<IconButton color="primary" sx={{ p: '10px' }} onClick={onclick}>*/}
                    {/*    <DirectionsIcon />*/}
                    {/*</IconButton>*/}
                    <Button variant="contained" onClick={onclick}>Записаться</Button>
                </React.Fragment>
                : null
        );
    }else if (type === 'tg'){


        tg.MainButton.text = "Записаться";
        if(phoneFormatCheck(phone) && !smsopen){
            // tg.MainButton.onClick(() => {
            //     console.log("CLICK" );
            //     onclick();
            // });
            tg.MainButton.show();

        }else{
            tg.MainButton.hide();
        }

        // if(smsopen){
        //     tg.MainButton.hide();
        // }
    }

}

const FormPhone = ({setPhone , phone , uname , serviceCart , clientstoreService , company_id , form_id ,
                       bookRecord , bookform ,bookrecord ,
                       tguserdata , getSmsCode , checkSmsCode ,
                       smscode : {type , success , loading } ,
                       smscode , u_phone , activity }
                    ) => {
    // let activity = useSelector((state) => state.activity);
    // if (useLocation().state) {
    //     activity = useLocation().state.activity;
    // }
    // console.log("START " , activity);
    console.log("FORM PHONE >>>" , phone);
   // console.log(tguserdata);
   // console.log("smscode" , type);
  // const localShop =  localStorage.getItem('shop');
   const phoneConfirmation = bookform.data.phone_confirmation;

   const {serviceCartItems , employeeCartItems , seanceDate} = serviceCart;
   const [smsOpen , setSmsOpen] = useState(false);
   const [showBtn , setShowBtn] = useState(false);
   const [showThnx , setThnx] = useState(false);
   const [error , setError] = useState(null);
   //const [loading , setLoading] = useState(null);
    let fullname = null;


    if (process.env.NODE_ENV === 'production') {
        if(!isObjEmpty(tguserdata)){
            fullname = `${tguserdata.user.first_name} ${tguserdata.user.last_name}`;
            let field = {
                phone ,
                uname: fullname
            }
            setPhone(field);
        }
    }

   useEffect(() => {
       // console.log("EFFER");

        if(bookrecord.error !== null){
            setError(bookrecord.error);
        }else{
            if(!isObjEmpty(bookrecord.bookrecord)){
                const {success} = bookrecord.bookrecord;
                if(success){
                    setThnx(true);
                }
            }
        }


    } , [bookrecord]);

   useEffect(() => {
       const phoneInput = document.querySelector("#data-input-tel");
       if(phoneInput){

           phoneMask.ListenKeyAndPaste(phoneInput);
       }
       // setPhone(phoneInput.value);
       setShowBtn(phoneFormatCheck(phone));
    } );

   // useEffect(() => {
   //     if(success){
   //         handleSmsClose();
   //         // record();
   //     }
   // } , [success])

    const handleChange = (e) => {
        //console.log(e.target);
        phoneMask.onPhoneInput(e);
        //inputRef.current.value = " hello world";
        //console.log(e.target.value)
        let fields = {
            uname ,
            phone: e.target.value
        }
        setPhone(fields);
    }

    const onChangeInputName = (e) => {
        let fields = {
            phone ,
            uname: e.target.value
        }
        setPhone(fields);
    }

    const snackBarClose = () => {
        setError(null);
    }

    const handleSmsOpen = () => {
        setSmsOpen(true);
    }

    const handleSmsClose = () => {
        setSmsOpen(false);
    }

    const handleCheckSms = ( code ) => {
        // const obj = { user_id: '111111' , phone: phone , code: code };
        // checkSmsCode({ shop_id: localShop, postData: obj});
        record({code: code , use: true});
        handleSmsClose();
    }

    const handleClick = () => {

       // console.log("CLICK " , activity);

        // const staff_id = employeeCartItems.id;
        if(phoneConfirmation){
            const _post = {phone: phone , fulname: uname};
            getSmsCode({company_id , postData: _post});
            handleSmsOpen();
           //  console.log("sms" , smsOpen);
        }else{
            record({ code: null, use: false }, activity);
            // if(phoneFormatCheck(phone)){
            //     const post = {
            //         phone : phone,
            //         fullname:uname ,
            //         email: "",
            //         appointments: [{
            //             services: createArrFromObjKeyId(serviceCartItems),
            //             datetime: strDateFormatYC(seanceDate) ,
            //             staff_id:  (staff_id === -1) ? 0 : staff_id  ,
            //             id: 0 }
            //         ] ,
            //         bookform_id: form_id ,
            //         tguserdata
            //     }
            //
            //     // console.log("Корзина" , post);
            //     bookRecord({company_id , post});
            // }
        }
    }

    const record = (code={code: null , use: false}, activity) => {
        // tgAlert(`into handleBookRecord => record ${phone} ${uname}` );

        // console.log("RECORD activity" , activity);
        const staff_id = employeeCartItems.id;
        // if(phoneFormatCheck(phone)){
        // console.log("RECORD PHONE >>>>" , phone);
            const post = {
                phone : phone,
                fullname:uname ,
                // phone : "89991383977",
                // fullname: "Евгений Кудряшов" ,
                email: "",
                appointments: !activity.id ? [{
                    services: createArrFromObjKeyId(serviceCartItems),
                    datetime: strDateFormatYC(seanceDate) ,
                    staff_id:  (staff_id === -1) ? 0 : staff_id  ,
                    id: 0 }
                ] : [{ activityId: activity.id, id: 0 }],
                bookform_id: form_id ,
                tguserdata ,
                code: code.use ? code.code : '',
            }
            // console.log("Корзина" , post);
            bookRecord({company_id , post});
        // }else{
        //     // tgAlert("into handleBookRecord => record => !phoneFormatCheck");
        //     setThnx(true);
        // }
    }

    const handleBookRecord = () => {
        record({ code: null, use: false }, activity);
    }



    return (
    <>
        <FullScreenDialog onOpen={smsOpen}
                          onClose={handleSmsClose}
                          loading={loading}
                          handle={handleCheckSms}
                          error={smscode.error}
                          info={{phone: phone}}
        />

        <PreFinalDisplay infoCart={serviceCart}
                         reduxCartClear={serviceCartClear}
                         bookRec={handleBookRecord}
                         recordLoading={bookrecord.loading}
                         recordError={error}
                         snackBarClose={snackBarClose}
                         final={showThnx}
                         phone={phone}
        />

    {/*{!showThnx ?*/}
    {/*        <PreFinalDisplay infoCart={serviceCart}*/}
    {/*                         reduxCartClear={serviceCartClear}*/}
    {/*                         bookRec={handleBookRecord}*/}
    {/*                         recordLoading={bookrecord.loading}*/}
    {/*                         recordError={error}*/}
    {/*                         snackBarClose={snackBarClose}*/}
    {/*                         final={showThnx}*/}
    {/*        />*/}
    {/*        :*/}
    {/*    */}
    {/*    <Box sx={{m:'20px'}}>*/}
    {/*        <Stack*/}

    {/*            justifyContent="center"*/}
    {/*            alignItems="center"*/}
    {/*            spacing={2}*/}
    {/*            sx={{marginBottom: '20px'}}*/}

    {/*        >*/}
    {/*            {bookrecord.loading?<MySpinner />:null}*/}
    {/*            {error ? <SnackbarNotify variant="error" boolOpen={true} message={error} setClose={snackBarClose}/> : null}*/}

    {/*        <Typography variant="h6" component="div">*/}
    {/*        Осталось чуть-чуть...*/}
    {/*        </Typography>*/}
    {/*        <Typography sx={{mt:2 }} color="text.secondary">*/}
    {/*            Укажите ваши данные, чтобы закончить запись*/}
    {/*        </Typography>*/}

    {/*        </Stack>*/}
    {/*        <Card elevation={0} sx={{backgroundColor: alpha(grey[700], 0.10), borderRadius: '10px'}}>*/}
    {/*            <CardContent>*/}
    {/*                <Stack*/}
    {/*                    direction="column"*/}
    {/*                    divider={<Divider  sx={{marginTop: '1px'}}/>}*/}
    {/*                    spacing={2}*/}
    {/*                >*/}
    {/*                    <Paper*/}
    {/*                        component="form"*/}
    {/*                        elevation={0}*/}
    {/*                        sx={paperStyle}*/}
    {/*                    >*/}
    {/*                        /!*<IconButton sx={{ p: '10px' }} aria-label="menu">*!/*/}
    {/*                        /!*    <PhoneForwardedIcon />*!/*/}
    {/*                        /!*</IconButton>*!/*/}
    {/*                        <InputLabel> Имя </InputLabel>*/}
    {/*                        <InputBase*/}
    {/*                            sx={{ ml: 1, flex: 1 }}*/}
    {/*                            placeholder="Имя Фамилия"*/}
    {/*                            value={fullname||""}*/}
    {/*                            inputProps={{ 'aria-label': 'Ваше имя' , 'id': 'data-input-name'  }}*/}
    {/*                            onChange={onChangeInputName}*/}

    {/*                            fullWidth*/}

    {/*                        />*/}

    {/*                    </Paper>*/}
    {/*                    <Paper*/}
    {/*                        component="form"*/}
    {/*                        elevation={0}*/}
    {/*                        sx={paperStyle}*/}
    {/*                    >*/}
    {/*                        /!*<IconButton sx={{ p: '10px' }} aria-label="menu">*!/*/}
    {/*                        /!*    <PhoneForwardedIcon />*!/*/}
    {/*                        /!*</IconButton>*!/*/}
    {/*                        <InputLabel> Тел. </InputLabel>*/}
    {/*                        <InputBase*/}
    {/*                            sx={{ ml: 1, flex: 1 }}*/}
    {/*                            placeholder="Ваш телефон"*/}
    {/*                            value={(phone === 'None' && phone === null) ? "" :  phone}*/}
    {/*                            inputProps={{ 'aria-label': 'Ваш телефон' , 'id': 'data-input-tel'  }}*/}
    {/*                            onChange={handleChange}*/}
    {/*                            fullWidth*/}
    {/*                            type="tel"*/}
    {/*                        />*/}
    {/*                        {process.env.NODE_ENV === 'development' && <PhoneSubmitButton onclick={handleClick} phone={phone} type='web' />}*/}
    {/*                    </Paper>*/}

    {/*                    {process.env.NODE_ENV === 'production' && <PhoneSubmitButton onclick={handleClick} phone={phone} type='tg' smsopen={smsOpen} />}*/}
    {/*                </Stack>*/}

    {/*            </CardContent>*/}
    {/*        </Card>*/}



    {/*    </Box>*/}
    {/*}*/}

</>

    );
}

const mapStateToProps = (state) => {


    const { phone : {phone , uname} , serviceCart ,smscode ,  bookform: {bookform , form_id , u_phone} ,
        bookRecord ,  bookrecord , tguserdata  , activity } = state;
   // console.log('mapStateToProps' , activity);
    return {
        phone ,
        uname ,
        serviceCart ,
        bookform ,
        activity ,
        company_id: bookform.data.company_id ,
        form_id ,
        bookrecord ,
        tguserdata ,
        smscode ,
    }
}

const mapDispatchToProps = (dispatch , {clientstoreService}) => {

    //const  = ownProps;
    return {
        setPhone : (fields) => dispatch(setUserPhone(fields)) ,
        bookRecord : requestBookRecord(clientstoreService , dispatch) ,
        getSmsCode: getSmsCode(clientstoreService , dispatch) ,
        checkSmsCode : checkSmsCode(clientstoreService , dispatch) ,
        serviceCartClear : () => dispatch(serviceCartClear()) ,
    }

}
export default withClientstoreService()(connect(mapStateToProps , mapDispatchToProps)(FormPhone));
// const PhoneSubmitButton = ({phone , onclick , type="web"}) => {
//     tg.MainButton.onClick(onclick);
//     if(type === 'web'){
//         return (
//             phoneFormatCheck(phone) ?
//                 <React.Fragment>
//                     {/*<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />*/}
//                     {/*<IconButton color="primary" sx={{ p: '10px' }} onClick={onclick}>*/}
//                     {/*    <DirectionsIcon />*/}
//                     {/*</IconButton>*/}
//                     <Button variant="contained" onClick={onclick}>Записаться</Button>
//                 </React.Fragment>
//                 : null
//         );
//     }else if (type === 'tg'){
//         tg.MainButton.text = "Записаться";
//         if(phoneFormatCheck(phone)){
//             tg.MainButton.show();
//         }else{
//             tg.MainButton.hide();
//         }
//     }
//
// }