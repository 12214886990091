import React , {Component , useEffect} from "react";
import Avatar from '@mui/material/Avatar';
import Card from "@mui/material/Card";
import CardHeader from '@mui/material/CardHeader';
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ErrorIndicator from "../error-indicator";
import SkeletonTimetable from "../skeletons/skeleton-timetable";
import { useNavigate } from "react-router-dom";
import {format, fromUnixTime, addMilliseconds, subMinutes} from 'date-fns';

const TimetablePanel = ({currentDate , formatDate , times , er , loading , addToCart , emptyService}) => {

    let curDat = `${currentDate}T00:00:00`; // костыль

    const navigate = useNavigate();

    let resEmpty = false;

    if(loading){
        //return (<MySpinner />);
        return (
            <SkeletonTimetable />

        );
    }

    if(er){
        return <ErrorIndicator />
    }

    const handleClick = (params={}) => {
        // console.log("TIME PARAMS" , params);
        addToCart(params);
        navigate("/", { state: { isRedirect: true } });
    }
    if(!Array.isArray(times)){
        resEmpty = true;
    }else{
        if(times.length < 1)resEmpty = true;
    }
    if(resEmpty){
        return ( <Card elevation={0}>

            <CardContent>
                <Typography component={'span'} variant="body2">
                    {formatDate(currentDate , 'DAY_MON')}

                    <Stack direction="row" spacing={1}>
                        Записей нет
                    </Stack>

                </Typography>
            </CardContent>

        </Card>);
    }
    // console.log("TIMES" ,times);


    /*{getTabDate(currentDate , 'DAY_MON')}*/
    return (
        <Card sx={{ minWidth: 350 }} elevation={0}>

            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: '#0072e5' }} aria-label="recipe">
                        <AssignmentIcon />
                    </Avatar>
                }

                title= {formatDate(currentDate , 'DAY_MON')}
                subheader=""
            />
            <CardContent>
                <Typography component={'span'} variant="body2">
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={1} >
                            {
                                times.map((el) => {
                                    return(

                                        <Grid item xs={4} sm={2} md={2} key={el.time} >

                                            <Button variant="outlined"
                                                    sx={{borderColor: 'rgb(25 118 210 / 24%)'}}
                                                    onClick={() => handleClick({date: new Date(curDat), time: el.time })}

                                            >
                                                <Typography  variant="caption">
                                                    {!emptyService && strTimeInterval(el)}
                                                    {emptyService && el.time}
                                                </Typography>

                                            </Button>

                                        </Grid>

                                    )
                                })

                            }


                        </Grid>
                    </Box>


                </Typography>
            </CardContent>

        </Card>
    );
}


const strTimeInterval = (time) => {
    // console.log("date time " , time);
    if(time.time){

        const subStrDate = time.datetime.substring(0 , time.datetime.length - 5);
        const lastChrStrDate = subStrDate.length - 1 ;
        const lastChr = subStrDate.substring( lastChrStrDate);
        const strDate = (lastChr === '+' || lastChr === '-') ? subStrDate.substring(0 , lastChrStrDate) : subStrDate ;
        // console.log("str date" , strDate);
        // if(lastChr === '+' || lastChr === '-') strDate = strDate.substring(0 , strDate.length - 1);
        const sDate = new Date(strDate);

        const endMls = addMilliseconds(sDate , time.sum_length*1000);

        const endTime = format(endMls , 'HH:mm');

        return `${time.time} - ${endTime}`
    }
}

export default TimetablePanel;