import React from "react";
import { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { useLocation } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material//Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";
import ListItemText from "@mui/material/ListItemText";
import Rating from "@mui/material/Rating";
import SkeletonCommentsList from "../skeletons/skeleton-comments-list";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


const SelectEmployeePageId = ({formId}) => {
   // const dispatch = useDispatch();

    const location = useLocation();

    const master = location.state;

   // const company_id = location.state.company_id;
    if (!master) {
        return (
            <Typography
                sx={{
                    textAlign: 'center',
                    color: '#808080',
                    fontSize: 15,
                    fontWeight: '400',
                }}
            >
                Нет информации о мастере.
            </Typography>
        );
    }
    const [comments, setComments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
// console.log("master" , master);
    useEffect(() => {
        async function fetchComments() {
            setIsError(false);
            const URL = `https://api.notibot.ru/comments/${formId}/${master.id}`;
            fetch(URL)
                .then((res) => res.json())
                .then((data) => {

                    setComments(data.data);
                    setIsLoading(true);
                })
                .catch((error) => {

                    setIsError(true);
                    setIsLoading(true);
                });
        };
        fetchComments();
    }, []);

// console.log("comments" , comments);

    return (
        <React.Fragment>
            <Card sx={{ minWidth: 275 }} elevation={0}>
                <Card elevation={0}>
                    <CardHeader
                        avatar={
                            <Avatar
                                src={master.avatar}
                                sx={{
                                    width: 80,
                                    height: 80,
                                }}
                            />
                        }
                        action={
                            <List sx={{ padding: 0 }} dense>
                                <ListItem
                                    sx={{
                                        padding: 0,
                                        marginRight: 1,
                                    }}
                                >
                                    <ListItemText
                                        secondary={
                                            master.show_rating === 1 ? (
                                                <Rating
                                                    name="read-only"
                                                    value={master.rating}
                                                    size="medium"
                                                    readOnly
                                                />
                                            ) : null
                                        }
                                    />
                                </ListItem>
                            </List>
                        }
                        title={
                            <Typography variant="h6">{master.name}</Typography>
                        }
                        subheader={
                            <Typography variant="subtitle1">
                                {master.specialization}
                            </Typography>
                        }
                    ></CardHeader>
                    <Divider
                        light
                        sx={{
                            marginBottom: 1,
                        }}
                    />
                    <CardContent>
                        <Typography variant="h7">
                            {master.information
                                ? ReactHtmlParser(master.information)
                                : "Информация о мастере будет обновлена в ближайшие дни."}
                        </Typography>
                    </CardContent>
                </Card>
                {(!isLoading && comments.length == 0) ||
                (isLoading && comments.length !== 0) ? (
                <div>
                    <Divider />
                    <Card sx={{ padding: 2, borderRadius: 0 }}>
                        <Typography variant="h6">Отзывы</Typography>
                        <Divider
                            light
                            sx={{
                                marginY: 1,
                            }}
                        />
                        {isLoading ? (
                            comments.map((comment) => (
                                <List key={comment.id}>
                                    <Card
                                        key={comment.id}
                                        sx={{ minWidth: 275 }}
                                        elevation={0}
                                    >
                                        <CardHeader
                                            sx={{
                                                cursor: "pointer",
                                                padding: 0,
                                                marginLeft: 1,
                                            }}
                                            avatar={
                                                <Avatar
                                                    src={comment.user_avatar}
                                                    sx={{
                                                        width: 40,
                                                        height: 40,
                                                    }}
                                                />
                                            }
                                            action={
                                                <List sx={{ padding: 0 }} dense>
                                                    <ListItem
                                                        sx={{
                                                            padding: 0,
                                                            marginRight: 1,
                                                        }}
                                                    >
                                                        <ListItemText
                                                            secondary={
                                                                comment.type ===
                                                                1 ? (
                                                                    <Rating
                                                                        name="read-only"
                                                                        value={
                                                                            comment.rating
                                                                        }
                                                                        size="medium"
                                                                        readOnly
                                                                    />
                                                                ) : null
                                                            }
                                                        />
                                                    </ListItem>
                                                </List>
                                            }
                                            title={
                                                <Typography>
                                                    {comment.user_name}
                                                </Typography>
                                            }
                                            subheader={
                                                <Typography
                                                    sx={{
                                                        color: "grey",
                                                    }}
                                                >
                                                    {comment.date}
                                                </Typography>
                                            }
                                        />
                                        <Typography
                                            sx={{
                                                margin: 1,
                                                fontSize: 16,
                                                fontWeight: "400",
                                            }}
                                        >
                                            {comment.text}
                                        </Typography>
                                    </Card>
                                    {comment.id !== comments.length ? (
                                        <Divider />
                                    ) : null}
                                </List>
                            ))
                        ) : (
                            <SkeletonCommentsList />
                        )}
                    </Card>
                </div>
                ) : (
                    <Typography
                        sx={{
                            color: "grey",
                            marginTop: 4,
                            fontSize: 24,
                            fontWeight: "400",
                            textAlign: "center"
                        }}
                    >
                        Отзывов нет.
                    </Typography>
                )}
            </Card>
            {/*<br></br>*/}
            {/*<br></br>*/}
            {/*<br></br>*/}
            {/*<br></br>*/}
            {/*<br></br>*/}
            {/*<Button*/}
            {/*    variant="contained"*/}
            {/*    sx={{*/}
            {/*        fontSize: 16,*/}
            {/*        borderRadius: 2,*/}
            {/*        width: "calc(100% - 16px)",*/}
            {/*        marginLeft: 1,*/}
            {/*        bottom: 40,*/}
            {/*        position: "sticky",*/}
            {/*        textTransform: "unset",*/}
            {/*        cursor: "pointer",*/}
            {/*        padding: 1,*/}
            {/*    }}*/}
            {/*>*/}
            {/*    Оставить отзыв*/}
            {/*</Button>*/}
        </React.Fragment>
    );
};

export default SelectEmployeePageId;
