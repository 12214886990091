import {searchItems} from "../utils/data-filter"

const setTgUserData = (user) => {
    return {
        type : 'SET_TG_USER_DATA' ,
        payload : user
    }
}

const setUserPhone = (fields) => {
    return {
        type : 'SET_USER_PHONE' ,
        payload : fields
    }
}

const servicesRequested = () => {
    return{
        type: 'FETCH_SERVICE_REQUEST'
    }
};

const servicesLoaded = (services) => {
    return {
        type : 'FETCH_SERVICE_SUCCESS',
        payload: services
    }
};

const servicesError = (error) => {
    return {
        type: 'FETCH_SERVICE_FAILURE' ,
        payload: error
    }
};

// => book form <=
const bookformRequested = () => {
    return{
        type: 'FETCH_BOOKFORM_REQUEST'
    }
};

export const bookformLoaded = (bookform) => {
    return {
        type : 'FETCH_BOOKFORM_SUCCESS',
        payload: bookform
    }
};

const bookformError = (error) => {
    return {
        type: 'FETCH_BOOKFORM_FAILURE' ,
        payload: error
    }
};
// => end <=
const serviceAddedToCart = (serviceId) => {
    return {
        type: 'SERVICE_ADDED_TO_CART',
        payload: serviceId
    };
}

export const serviceCartClear = () => {
    return {
        type: 'SERVICE_CART_CLEAR'
    };
}

const employeeAddedToCart = (params) => {
    // console.log('employeeAddedToCart' , params);
    return {
        type : 'EMPLOYEE_ADDED_TO_CART' ,
        payload : params
    }

}



const employeesRequested = () => {
    return{
        type: 'FETCH_EMPLOYEE_REQUEST'
    }
};

const employeesLoaded = (employees) => {

    return {
        type : 'FETCH_EMPLOYEE_SUCCESS',
        payload: employees
    }
};

const employeesError = (error) => {
    return {
        type: 'FETCH_EMPLOYEE_FAILURE' ,
        payload: error
    }
};

const bookTimesRequested = () => {
    return{
        type: 'FETCH_BOOKTIMES_REQUEST'
    }
};

const bookTimesLoaded = (bookTimes) => {

    return {
        type : 'FETCH_BOOKTIMES_SUCCESS',
        payload: bookTimes
    }
};

const bookTimesError = (error) => {
    return {
        type: 'FETCH_BOOKTIMES_FAILURE' ,
        payload: error
    }
};

const bookDatesRequested = () => {
    return{
        type: 'FETCH_BOOKDATES_REQUEST'
    }
};

const bookDatesLoaded = (bookdates) => {

    return {
        type : 'FETCH_BOOKDATES_SUCCESS',
        payload: bookdates
    }
};

const bookDatesError = (error) => {
    return {
        type: 'FETCH_BOOKDATES_FAILURE' ,
        payload: error
    }
};

const seancesRequested = () => {
    return{
        type: 'FETCH_SEANCES_REQUEST'
    }
};

const seancesLoaded = (seances) => {
//console.log('seancesLoaded Action' , seances);
    return {
        type : 'FETCH_SEANCES_SUCCESS',
        payload: seances
    }
};

const seancesError = (error) => {
    return {
        type: 'FETCH_SEANCES_FAILURE' ,
        payload: error
    }
};

const smscodeRequested = () => {
    return{
        type: 'FETCH_SMSCODE_REQUEST'
    }
};

const smscodeLoaded = () => {
//console.log('seancesLoaded Action' , seances);
    return {
        type : 'FETCH_SMSCODE_SENDED',
       // payload: status
    }
};

const smscodeSuccess = () => {
//console.log('seancesLoaded Action' , seances);
    return {
        type : 'FETCH_SMSCODE_SUCCESS',
        // payload: status
    }
};


const smscodeError = (error) => {
    return {
        type: 'FETCH_SMSCODE_FAILURE' ,
        payload: error
    }
};

const bookRecordRequested = () => {
    return{
        type: 'FETCH_BOOKRECORD_REQUEST'
    }
};

const bookRecordLoaded = (bookrecord) => {
//console.log('seancesLoaded Action' , seances);
    return {
        type : 'FETCH_BOOKRECORD_SUCCESS',
        payload: bookrecord
    }
};

const bookRecordError = (error) => {
    return {
        type: 'FETCH_BOOKRECORD_FAILURE' ,
        payload: error
    }
};

// UPDATE_CART_FROM_RECORD_INFO

const recordByIdInfoRequested = () => {
    return{
        type: 'UPDATE_CART_FROM_RECORD_INFO_REQUEST'
    }
};

const recordByIdInfoLoaded = (record) => {
// console.log('seancesLoaded Action' , seances);
    return {
        type : 'UPDATE_CART_FROM_RECORD_INFO',
        payload: record
    }
};

const recordByIdInfoError = (error) => {
    return {
        type: 'UPDATE_CART_FROM_RECORD_INFO_FAILURE' ,
        payload: error
    }
};



export const setCurrentFormId = (formId) => {
    return {
        type: 'SET_CURRENT_FORM_ID' ,
        payload: formId
    }
};

export const setCurrentCityId = (cityId) => {
    return {
        type: 'SET_CURRENT_CITY_ID' ,
        payload: cityId
    }
};

export const setCurrentCompId = (companyId) => {
    return {
        type: 'SET_CURRENT_COMPANY_ID' ,
        payload: companyId
    }
};

export const setCurrentRecordId = (recordObj={}) => {

    return {
        type: 'SET_CURRENT_RECORD_ID' ,
        payload: recordObj
    }
};

export const setCurrentUserPhone = (phone) => {

    return {
        type: 'SET_CURRENT_USER_PHONE' ,
        payload: phone
    }
};

export const setHiddenMaster = (hid) => {
    return {
        type: 'SET_HIDDEN_MASTER' ,
        payload: hid
    }
};

export const setCurrentBookDate = (date) => {
    return {
        type: 'SET_CURRENT_BOOKDATE' ,
        payload: date
    }
}

export const setCurrentEmployeeSeance = (eID) => {
    //console.log('setCurrentEmployeeSeance' , eID)
    return {
        type: 'SET_CURRENT_EID_SEANCE' ,
        payload: eID
    }
}

export const setCurrentEID = (eID) => {
    //console.log('setCurrentEmployeeSeance' , eID)
    return {
        type: 'SET_CURRENT_EID' ,
        payload: eID
    }
}

export const updateEmployeeListByTerm = (bool) => {
    //console.log('updateEmployeeListByTerm' , bool)
    return {
        type: 'UPDATE_EMPLOYEE_LIST' ,
        payload: bool
    }
}

// глобальная строка поиска
export const setCurrentTerm = (term) => {
    //console.log('setCurrentEmployeeSeance' , term)
    return {
        type: 'SET_CURRENT_TERM' ,
        payload: term
    }
}



const fetchBookTimes = (clientstoreService , dispatch) => (obj) => {
   // console.log('fetch Times' , obj);
    const {eId,  date , company_id , services , tgid } = obj;

    dispatch(bookTimesRequested());
    clientstoreService.getBookTimes(eId , date , company_id , tgid, services )
        .then((data) => {
            //console.log('fetch time' , data);
            dispatch(bookTimesLoaded(data));
        })
        .catch((err) => dispatch(bookTimesError(err))
        );
}

const fetchBookform = (clientstoreService , dispatch) => (obj={}) =>  {
   // console.log("action" , obj);
    const {formId , tgid} = obj;

    dispatch(bookformRequested());
    clientstoreService.getBookForm(formId , tgid)
        .then((data) => {
           // console.log("fetchBookForm data:::" , data);
            dispatch(bookformLoaded(data));
        })
        .catch((err) => dispatch(bookformError(err)));
}

const fetchBookDates = (clientstoreService , dispatch) => (obj) => {

    const {eId,  services , company_id ,tgid } = obj;

    dispatch(bookDatesRequested());
    // async getBookDates(eId = 0 , serviceIds=[] , datetime='' )
    clientstoreService.getBookDates({eId , serviceIds: services , company_id , tgid})
        .then((data) => {
             //console.log('ACTION fetch' , data);
            const booking_dates = data.booking_dates;
            if(Array.isArray(booking_dates) ){
                let curdate = booking_dates[0];
                dispatch(setCurrentBookDate(curdate));
            }
            dispatch(bookDatesLoaded(data));
        })
        .catch((err) => dispatch(bookDatesError(err))
        );
}

const fetchBookSeancesEmployees = (clientstoreService , dispatch) => (eID = 0 , obj ={}) => {
    let mas = {};
    const {services , datetime ,  company_id , tgid } = obj;
    dispatch(seancesRequested());
    clientstoreService.getBookEmployeeSeances(eID , company_id , services , datetime , tgid )
        .then((datas) => {
            // console.log('MAP' , datas);
            mas = {
                id: eID ,
                seances: datas.seances ,
                seance_date: datas.seance_date
            };
            dispatch(setCurrentEID(eID));
            dispatch(seancesLoaded(mas));
        })
        .catch((err) => dispatch(seancesError(err))
        );
}

const fetchEmployees = (clientstoreService , dispatch) => (obj={}) => {
    dispatch(employeesRequested());
    // console.log('FetchEmployees ==> obj' , obj);
    const {services , datetime , term , company_id , tgid } = obj;
    const { defMasterId  } = obj;
    // console.log('defMaster' , defMasterId);
    // let servids = [];
    clientstoreService.getEmployeeList(services , datetime , company_id , 0 , tgid )
        .then((data) => {

            let mas = {};
            data.map((el) => {
                // console.log('MAP' , el.id);
                dispatch(seancesRequested());

                // clientstoreService.getBookEmployeeSeances(el.id , company_id , services , datetime )
                //     .then((datas) => {
                //         // console.log('MAP' , datas);
                //         mas = {
                //             id: el.id ,
                //             seances: datas.seances ,
                //             seance_date: datas.seance_date
                //         };
                //         dispatch(setCurrentEID(el.id));
                //         dispatch(seancesLoaded(mas));
                //     })
                //     .catch((err) => dispatch(seancesError(err))
                //     );

            });
            // мастер из формы по умолчанию при отключенном вбыоре мастеров
            if(defMasterId )dispatch(setCurrentEID(defMasterId));
            // console.log(`fetchEmployeeSeances THEN ` ,  data)
            let visibleItems = data;
            visibleItems = searchItems(data , term , 'name');
            // console.log(`visibleItems ` ,  visibleItems);
            dispatch(employeesLoaded(visibleItems));
        })
        .catch((err) => dispatch(employeesError(err))
        );
}

const fetchEmployeeSeances = (clientstoreService , dispatch) => (eId) => {

    //console.log('fetchEmployeeSeances callback' ,  eId);
    dispatch(seancesRequested());
    clientstoreService.getBookEmployeeSeances(eId )
        .then((data) => {
            const mas = {
                id: eId ,
                data: data
            };
            //data.push(mas);
            //console.log(`fetchEmployeeSeances THEN ${eId}` ,  mas);
            dispatch(seancesLoaded(mas));
        })
        .catch((err) => dispatch(seancesError(err))
        );

}

const updateBookRecord = (clientstoreService , dispatch) => (obj={}) => {
    const {company_id , record_id , datetime } = obj;
    dispatch(bookRecordRequested());
    // console.log("upd POST" , datetime);
    clientstoreService.updateRecordById(company_id , record_id , datetime )
        .then((data) => {
           // console.log("RESPONSE POST DATA" ,data);
            if(data.success === false){
                //console.log(data.meta.message);

                throw new Error(data.meta.message);
                // dispatch(bookRecordError(`${error}`));

                // const _errors = data.meta.errors;
                // let strErrors = data.meta.message;
                // for ( var key in _errors) {
                //     strErrors = strErrors + `${_errors[key]}\r\t`;
                // }
                //
                // throw new Error("Ошибка записи");

            }else{
                dispatch(bookRecordLoaded(data));
            }
            // dispatch(bookRecordLoaded(data));
        })
        .catch((error) =>{
            //console.log(error);
            dispatch(bookRecordError(`${error}`));
        });

}

const requestBookRecord = (clientstoreService , dispatch) => (obj={}) => {
    const {company_id , post} = obj;
    dispatch(bookRecordRequested());

    clientstoreService.bookRecord(company_id , post)
        .then((data) => {
            // console.log("RESPONSE POST DATA" ,data);
            setTimeout(() => {
                if(data.success === false){
                    //console.log(data.meta.message);

                    throw new Error(data.meta.message);

                    // const _errors = data.meta.errors;
                    // let strErrors = data.meta.message;
                    // for ( var key in _errors) {
                    //     strErrors = strErrors + `${_errors[key]}\r\t`;
                    // }

                    // throw new Error("Ошибка записи");

                }
                dispatch(bookRecordLoaded(data));
            }, 1000);

        })
        .catch((error) =>{
            //console.log(error);
            dispatch(bookRecordError(`${error}`));
        });

}

const fetchServices = (clientstoreService , dispatch) => ({id = 0 , datetime='' , services=[] , term='' , company_id , tgid=0}) => {

    dispatch(servicesRequested());
    // console.log("DATE ACTION TG ID" , tgid);

    clientstoreService.getAllServices(id , datetime , services , company_id , tgid)
        .then((data) => {
          console.log('fetch' , data);

            if(data.success){
                const ddata = data.data;
                // поиск по параметру term
               // console.log("data data" , ddata.services);

                let visibleServices = {category: ddata.category ,
                    events: ddata.events ,
                    services: searchItems(ddata.services , term , 'title')};

                //visibleServices = searchItems(data.data.services , term , 'title');
                //console.log("VISIBLE" , visibleServices);
                dispatch(servicesLoaded(visibleServices));
            }else if(!data.success){
                dispatch(servicesError(data.meta.message))
            }

        })
        .catch((err) => dispatch(servicesError(err))
        );
};

const getSmsCode = (clientstoreService , dispatch) => ({company_id , postData}) => {
    // const {company_id , _post} = obj;
    // console.log("POst" , postData);
    dispatch(smscodeRequested());
    clientstoreService.getSmsCode( company_id , postData)
        .then((data) => {
           // console.log("RESPONSE POST DATA" ,data);
            if(data.success === false){
               // console.log(data.meta.message);
                throw new Error(data.meta.message);

            }
            dispatch(smscodeLoaded());
        })
        .catch((error) =>{
            //console.log(error);
            dispatch(smscodeError(`${error}`));
        });
}

const checkSmsCode = (clientstoreService , dispatch) => ({shop_id , postData}) => {
    // const {company_id , _post} = obj;
    // console.log("POst" , postData);
    dispatch(smscodeRequested());
    clientstoreService.checkSmsCode( shop_id , postData)
        .then((data) => {
            // console.log("RESPONSE POST DATA" ,data);
            if(data.success === false){
              //  console.log(data.meta.message);
                throw new Error(data.meta.message);

            }
            dispatch(smscodeSuccess());
        })
        .catch((error) =>{
            //console.log(error);
            dispatch(smscodeError(`${error}`));
        });
}

const getRecordById = (clientstoreService , dispatch) => (obj={}) => {
   const {shop_id , record_id} = obj ;
    dispatch(recordByIdInfoRequested());
    clientstoreService.getRecordInfo( shop_id , record_id)
        .then((data) => {
           // console.log("RESPONSE RECORD DATA" ,data);
           // console.log("RESPONSE CLIENT" ,data.client.phone);
            // сформируем новый объект только из нужных полей
            const newObj = {id: data.id ,
                services: data.services ,
                staff: data.staff ,

            }
            // запишем номер телефона из деталей записи


           dispatch(setUserPhone({uname:data.client.display_name , phone: data.client.phone}));
            dispatch(recordByIdInfoLoaded(newObj));

        })
        .catch((error) => {
           // console.log("RESPONSE RECORD ERROR" ,error);
            dispatch(recordByIdInfoError(error));
        });
}

export {
    fetchServices ,
    serviceAddedToCart ,
    employeeAddedToCart ,
    fetchEmployees,
    fetchBookSeancesEmployees ,
    fetchBookTimes,
    fetchBookDates,
    fetchEmployeeSeances ,
    setUserPhone ,
    fetchBookform ,
    requestBookRecord ,
    setTgUserData ,
    getSmsCode , checkSmsCode ,
    getRecordById ,
    updateBookRecord
}


// const orderLoaded = (currentOrderInfo) => {
//     return {
//         type: 'ORDER_LOADED',
//         payload: currentOrderInfo
//     }
// };