import {dateFormater} from './date-formater';
import isObjEmpty from "./empty-object";
import {format, fromUnixTime , getUnixTime} from "date-fns";

const strDateToUnix = (datetime) => {
    // const regex = /\b(\d)\b/gm;
    // const subst = `0$1`;

    try {
        if(datetime.date === null){
            throw new Error("Не выбранна дата сеанса");
        }
        let strDate = dateFormater(datetime.date , 'STD');
        // const dTime = datetime.time.replace(regex, subst);
        const strTime = datetime.time;
        let strdate = `${strDate} ${strTime}:00`;
        return getUnixTime(new Date(strdate));
    }catch (e){
        alert("Не можем обработать запрос , так как не выбрана дата");
    }
}

const strDateFormatYC = (datetime) =>{
    // console.log("strDateFormatYC datetime" , datetime);
    const regex = /\b(\d)\b/gm;
    const subst = `0$1`;
    try {
        if(datetime.date === null){
            throw new Error("Не выбранна дата сеанса");
        }
        let strDate = dateFormater(datetime.date , 'STD');

        const strTime = datetime.time.replace(regex, subst);
        // console.log("dTIME" , dTime);
       // const strTime = dTime; // datetime.time;
        let strdate = `${strDate}T${strTime}:00`;
        return strdate;
    }catch (e){
      alert("Не можем обработать запрос , так как не выбрана дата");
    }

}

const dataFilter = ({id=0 , datetime=''  ,services=[] , date='' , tgid}) => {

    // console.log("dataFilter SERVICES ID ===>", services ,);
    let staffId = 0;
    let fDateTime = '';
    let fDate = '';


    if(id !== undefined){
        staffId = id;
    }

    if(datetime !== '' && !isObjEmpty(datetime)){
        let strdate = strDateFormatYC(datetime);

        fDateTime= strdate;//finDate.toISOString();
    }
    if(date){
        fDate = date;
    }
    //console.log("FDATE" , fDate === '')
    if(services.length > 0 ){
        // console.log("stafId 2 ===>",staffId);
        let objRes = {staffId , datetime: fDateTime , date: fDate , serviceIds: createArrFromObjKeyId(services)};
        // let objRes = {};
        if(fDateTime !== ''){
            objRes = {staffId ,  datetime: fDateTime , serviceIds: createArrFromObjKeyId(services)};
        }
        if(fDate !== ''){
            objRes = {staffId ,  date: fDate , serviceIds: createArrFromObjKeyId(services)};
        }

        objRes = {...objRes , tgid}
        // console.log("OBJ" , objRes);

        return strApiParamForFilter(objRes);
    }
   // console.log("stafId 3 ===>",staffId);
     return strApiParamForFilter({staffId , datetime: fDateTime , tgid});
}

const strApiParamForFilter = ({staffId = 0 , serviceIds =[] , datetime='' , date='' , tgid=0 } ) => {

   // console.log("STR FILTER" , serviceIds);
    let strServices = '' // (serviceIds.length > 0) ? `&service_ids%5B%5D=${serviceIds}`: '';

    let strDatetime = datetime !== '' ? `&datetime=${datetime}`:'';
    let strStaffId = staffId === 0 ? '' : `staff_id=${staffId}`;
    let strDate = date !== '' ? `&date=${date}` : '';
    const onlyServices = (strDatetime === '' && strStaffId === '' && strDate=== '') ? true : false;
    if(serviceIds.length > 0){

        serviceIds.forEach((el , ind) => {
            // console.log("onlyServices" , onlyServices);
            if(onlyServices && ind === 0){
                strServices = `${strServices}service_ids%5B%5D=${el}`;
            }else{
                strServices = `${strServices}&service_ids%5B%5D=${el}`;
            }
        });
    }

    return `?tg_id=${tgid}&${strStaffId}${strDate}${strDatetime}${strServices}`;
}

const createArrFromObjKeyId = (obj={}) => {

    let newArr = [];
    if(isObjEmpty(obj)){
        return obj;
    }

    obj.map((el)=>{
        newArr.push(el.id);
    });

    return newArr;
}

const searchItems = (items, search , label='name') => {
    if (search.length === 0) {
        return items;
    }

    return items.filter((item) => {
        let newItem = item[`${label}`];
        return newItem.toLowerCase().indexOf(search.toLowerCase()) > -1;
    });
}

const strTimeIntervalYC = (time , return_time_end = true) => {
    if(time.time){
        const sec = Date.parse(time.datetime)/1000;

        const secSum = sec+time.sum_length;
        // console.log(secSum);
        const endTime = format(fromUnixTime(secSum), 'kk:mm');
        // console.log("endTime" , endTime);
        const strTime = return_time_end ? `${time.time} - ${endTime}` : `${time.time}`;
        return strTime; // `${time.time} - ${endTime}`
    }
}

export {dataFilter , createArrFromObjKeyId , strApiParamForFilter , searchItems , strDateFormatYC , strTimeIntervalYC , strDateToUnix};
