import {isObjEmpty } from "../utils";
import {dataFilter} from "../utils/data-filter"

export default class ClientstoreService {

    // _apiBase = 'https://api.notifio.ru/';
     _apiBase = 'https://api.notibot.ru/';
    //
    //_apiBase = 'https://functions.yandexcloud.net/d4elp2tp15504qbno4gu?p=';
    _companyId = '775535';//'108190';
    _formId = '';

    setCompanyID (cid) {
       // console.log("CID" , cid);
        this._companyId = cid;
    }

    async putQuery(url , body={}){

        // const api = 'https://api.notifio.ru/'
        // const api = 'https://api.notibot.ru/'
        const rawResponse = await fetch(`${this._apiBase}${url}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        const content = await rawResponse.json();

        //console.log(content);
        return content;
    }

    async postQuery(url , body={}){

       // const api = 'https://api.notifio.ru/'
        // const api = 'https://api.notibot.ru/'
       const rawResponse = await fetch(`${this._apiBase}${url}`, {
            method: 'POST',
            headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
       });
       const content = await rawResponse.json();

       //console.log(content);
       return content;
    }

    async bookRecord(company_id =0 , post={}){
        const req = `book_record/${this._companyId}`;
        this.setCompanyID(company_id);
        const res = await this.postQuery(req , post);
        return res;
    }

    async getBookForm(formId , tgid=0){
       console.log("getBookForm" , `bookform/${formId}?tg_id=${tgid}`);
        const req = `bookform/${formId}?tg_id=${tgid}`;
        const res = await this.getResource(req);
        return res;
    }

    async getResource(url) {

        const res = await fetch(`${this._apiBase}${url}`  );

        if (!res.ok) {
            throw new Error(`Could not fetch ${url}` +
                `, received ${res.status}`)
        }
        return await res.json();
    }

    async getAllServices(staffId = 0 , datetime='' , services_ids=[] , company_id=0 , tgid=0) {

        //https://api.yclients.com/api/v1/book_services/{company_id}
        //console.log("STRING" , staffId);
        this.setCompanyID(company_id);
        const strApiParam = dataFilter({id : staffId , datetime: datetime , services:services_ids , tgid: tgid });

        ///console.log("clientstore-service.js line 28 ApiUrl ==> " , strApiParam );

        const req = `book_services/${this._companyId}${strApiParam}`;
       console.log("==> API URL ==>" , req);
        const res = await this.getResource(req);

        return res;
    }

    returnPromise(data){
        return new Promise((resolve , reject ) => {
            setTimeout(()=>{
                resolve(data);
                //console.log(this.data);

                //reject(new Error('Something bad happened'));
            } , 700);
        });
    }
    getServicesList(){

        return this.returnPromise(this.data);
    }

    async getSmsCode(company_id =0 , post={}){
        const req = `book_code/${this._companyId}`;
        this.setCompanyID(company_id);
        const res = await this.postQuery(req , post);
        return res;
    }
    async checkSmsCode(shop_id =0 , post={}){
        const req = `yc_auth/${shop_id}`;
       // this.setCompanyID(company_id);
        const res = await this.postQuery(req , post);
        return res;
    }

    async getRecordInfo(shop_id , rec_id){
        const req = `book_record/${shop_id}/${rec_id}`;
        const res = await this.getResource(req);
       // console.log("result" , res);
        return res.data;
    }

    async updateRecordById(shop_id , rec_id , datetime){
        const req = `book_record/${shop_id}/${rec_id}`;
        // console.log("result" , datetime);
        const res = await this.putQuery(req , {datetime});
        // console.log("result" , res);
        return res.data;
    }

    async getAllEmployees(){
       // book_staff/${this._companyId}&datetime=&without_seances=1
        const req = `book_staff/${this._companyId}&datetime=&without_seances=1`;
        const res = await this.getResource(req);
        return res.data.map(this._transformData);
    }

    // ближайшая доступная дата
    async getBookEmployeeSeances(eId , company_id=0 , serviceIds=[] , datetime='' , tgid=0){
        //https://api.yclients.com/api/v1/book_staff_seances/{company_id}/{staff_id}/
        //console.log(company_id);
        this.setCompanyID(company_id);
        const strApiParam = dataFilter({staffId : eId  , datetime: datetime , services : serviceIds , tgid });
        // console.log("strApiParam" , strApiParam );
        const req = `book_staff_seances/${this._companyId}/${eId}${strApiParam}`;
        // const req = `book_staff_seances/${this._companyId}/${eId}/`;
       // console.log(`URL ${eId} >>>` , req);
        const res = await this.getResource(req);
        // console.log(`response ${eId} >>> ` , res);
        return res;
        //return this._addSeancesToEmployee(res , eId);
    }

    async getEmployeeList(serviceIds=[] , datetime='' , company_id=0 , staffId = 0 , tgid=0){
        this.setCompanyID(company_id);
        const strApiParam = dataFilter({staffId : staffId  , datetime: datetime , services : serviceIds , tgid });

        const req = `book_staff/${this._companyId}${strApiParam}&without_seances=1`;

        const res = await this.getResource(req);

        // console.log("RES >>>" , res);

        return res.data;  //this.returnPromise(this.employeeData);
    }

    async getBookTimes(eId = 0 , date ,  company_id=0 , tgid=0 , services){
        // console.log("SERVICES" , services);
        let arServices = [];
        if(Array.isArray(services)){
            arServices = services;
        }else{
            arServices = [services];
        }
        this.setCompanyID(company_id);
        //console.log('GET BOOK TIMES DATE' , date);
        const strApiParam = dataFilter({services : arServices , tgid });
       // const req = `book_times/${this._companyId}/${eId}/${date}`;
        const req2 = `book_times/${this._companyId}/${eId}/${date}${strApiParam}`;
        // console.log("URL" , req2);
        const res = await this.getResource(req2);
        // console.log('GET BOOK TIMES' , res);
        return this._transformResultGetItemData(res);
    }

    async getBookDates({eId , serviceIds , datetime , company_id , tgid}) {
        // console.log("getBookDates" , datetime);
       // console.log("nenO" , company_id );
        this.setCompanyID(company_id);
        const strApiParam = dataFilter({id : eId , services : serviceIds , datetime: datetime , date: '' , tgid });
        const req = `book_dates/${this._companyId}${strApiParam}`;
        const res = await this.getResource(req);
        //console.log("==> API URL BOOK DATES ==>" , req);
        return this._transformResultGetItemData(res); //res.data;
    }

    _transformData(element){
        return {
            ...element
        };
    }

    _transformResultGetItemData(res){
        return res.data;
    }
    _addSeancesToEmployee = (seance , id) => {
        //console.log('_addSeancesToEmployee' , seances.data);
        return {
            id: id,
            ...seance
        }
    }

    _getTabDate (date , opt='DAY') {
        // console.log("ORIG DATE" , date);
        let myDate = (Date.parse(date) === NaN || date === undefined)? new Date(): new Date(`${date}T00:00:00`);


        if(date === '0000' ){
            myDate = new Date();
        }
        // console.log("DATE" , myDate);

        const f_num_day = new Intl.DateTimeFormat("ru", {
            day: "numeric" });
        const f_short_day = new Intl.DateTimeFormat("ru", {
            weekday: "short" });
        const f_day_mon = new Intl.DateTimeFormat("ru", {
            day: "numeric", month: "long" });
        const f_standart = new Intl.DateTimeFormat("sv");




        const num_day = f_num_day.format(myDate);
        const short_day = f_short_day.format(myDate);
        // console.log("NUM_DAY" , num_day);
        const utcDate =new Date(Date.parse(date));
        // console.log("UTC" , utcDate.toUTCString());

        switch(opt){
            case 'DAY':
                return short_day;
            case 'nDAY':
                return num_day;
            case 'DAY_MON':
                return f_day_mon.format(myDate);
            case 'STD':
                return f_standart.format(myDate);
            default: return short_day;
        }


    }

    // _getTabDate (date , opt='DAY') {
    //     console.log("DATE" , date);
    //     let myDate = (Date.parse(date) === NaN || date === undefined)? new Date(): new Date(date);
    //
    //
    //     if(date === '0000' ){
    //         myDate = new Date();
    //     }
    //
    //
    //     const f_num_day = new Intl.DateTimeFormat("ru", {
    //         day: "numeric" });
    //     const f_short_day = new Intl.DateTimeFormat("ru", {
    //         weekday: "short" });
    //     const f_day_mon = new Intl.DateTimeFormat("ru", {
    //         day: "numeric", month: "long" });
    //     const f_standart = new Intl.DateTimeFormat("sv");
    //
    //
    //
    //
    //
    //     const num_day = f_num_day.format(myDate);
    //     const short_day = f_short_day.format(myDate);
    //
    //
    //     switch(opt){
    //         case 'DAY':
    //             return short_day;
    //         case 'nDAY':
    //             return num_day;
    //         case 'DAY_MON':
    //             return f_day_mon.format(myDate);
    //         case 'STD':
    //             return f_standart.format(myDate);
    //         default: return short_day;
    //     }
    //
    //
    // }

}