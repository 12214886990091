import React , { useEffect , useState } from 'react';
import {withClientstoreService} from "../hoc";
import {connect} from "react-redux";
import {requestBookRecord, serviceCartClear , updateBookRecord} from "../../actions";
import {strDateFormatYC , strDateToUnix} from "../../utils/data-filter";
import {FinalDisplay} from "../final-display";
import MySpinner from "../spinner";
import SnackbarNotify from "../snackbar-notify";


const MoveRecord = ({record_id , form_id ,  bookrecord , updBookRecord , serviceCart}) => {
// console.log("Rec" , record_id);
// console.log("date" , strDateToUnix(serviceCart.seanceDate.date));
    const [error , setError] = useState(null);

    const snackBarClose = () => {
        setError(null);
    }
    useEffect(() => {
        updBookRecord({
            company_id: form_id,
            record_id ,
            datetime: strDateFormatYC(serviceCart.seanceDate) ,
        });
    } , []);

    useEffect(() => {
        if(bookrecord.error !== null){
            setError(bookrecord.error);
        }
    }, [bookrecord.error , setError]);

    return (
        <>
            {error &&  <SnackbarNotify variant="error" boolOpen={true} message={error} setClose={snackBarClose}/> }

            {bookrecord.loading && <MySpinner /> }
            {(!bookrecord.loading && error === null ) &&
                <FinalDisplay infoCart={serviceCart}
                                 reduxCartClear={serviceCartClear}
                />
            }

        </>
    );
}
// /book_record/{shop_id}/{record_id} put запрос в теле datetime - перенести запись, остальные запросы у тебя есть
const mapStateToProps = (state) => {
    // console.log('mapStateToProps' , state);
    const {  serviceCart  , bookform: {bookform , form_id , record_id , action} ,
        bookRecord ,  bookrecord , tguserdata  } = state;
    return {

        serviceCart ,
        bookform ,
        company_id: bookform.data.company_id ,
        form_id ,
        record_id ,
        bookrecord ,
        tguserdata
    }
}

const mapDispatchToProps = (dispatch , {clientstoreService}) => {

    //const  = ownProps;
    return {

        updBookRecord : updateBookRecord(clientstoreService , dispatch) ,
        serviceCartClear : () => dispatch(serviceCartClear()) ,
    }

}


export default withClientstoreService()(connect(mapStateToProps , mapDispatchToProps)(MoveRecord));