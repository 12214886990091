import React, { useEffect, useLayoutEffect, useState } from "react";
import { connect } from "react-redux";

import Skeleton from "@mui/material/Skeleton";
import { withClientstoreService } from "../hoc";
import ErrorIndicator from "../error-indicator";
import {
    fetchEmployees,
    fetchBookSeancesEmployees ,
    fetchEmployeeSeances,
    employeeAddedToCart,
    setCurrentTerm,
} from "../../actions";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import EmployeeListItem from "../employee-list-items";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import EmployeeAnyItem from "./employee-any-item";
import { useNavigate } from "react-router-dom";
import { isObjEmpty } from "../../utils";
import Alert from "@mui/material/Alert";

const EmployeeList = ({
    employees,
    seances,
    seanceDate,
    currentEmpId,
    addToCart,
    fetchSeances ,
    setCurrentTerm,
    sLoading ,
    term,
    objFilter
}) => {

// console.log("SEANCES ___" , seances);

    const navigate = useNavigate();

    const addItemCart = (masterId, dates = {}) => {
        //console.log("addItemCart" , dates);
        if (!isObjEmpty(dates)) {
            addToCart({ mid: masterId, time: dates.time, date: dates.dates });

        } else {
            addToCart({ mid: masterId });
        }
        navigate("/", { state: { isRedirect: true } });
    };

    // сортировка по bookable
    const sortMaster =  employees.sort((x) => x.bookable ? -1 : 1 );
    // if (sLoading) {
    //     return (
    //         <SkeletonStaffCard/>
    //     );
    // }

    return (
        <React.Fragment>
            <EmployeeAnyItem addItemCart={addItemCart} />
            {sortMaster.map((master) => {
                //console.log('===//>' , seances[master.id]);
                // проверить параметр bookable
                //console.log("master" , master);
               return (
                    <EmployeeListItem
                        key={master.id}
                        master={master}
                        fetchSeances={fetchSeances}
                        seance={seances[master.id]}
                        currentEmpId={currentEmpId}
                        addToCart={addToCart}
                        addItemCart={addItemCart}
                        term={term}
                        sLoading={sLoading}
                        seanceDate={seanceDate}
                        objFilter={objFilter}
                    />
               );
            })}
        </React.Fragment>
    );
};

const EmployeeListContainer = ({
    employees,
    eLoading,
    eError,
    seances,
    sLoading,
    fetchEmployees,
    fetchBookSeances ,
    employeeAddedToCart,
    currentEmpId,
    serviceCart,
    seanceDate,
    setCurrentTerm,
    term,
    update,
    company_id,
    tgid ,
}) => {
    useLayoutEffect(() => {
        setCurrentTerm("");

    }, []);

    const empty = Array.isArray(employees) && employees.length === 0 ;

    const emptyContent = (
        <>
            <Alert sx={{m:1}}>
                Нет доступных сотрудников
            </Alert>
        </>
    );


    useEffect(() => {
       // console.log("EFFECT" , seanceDate);
        // создаём массив с ID  услуг из объекта
        const { serviceCartItems } = serviceCart;
        //const serviceIds = createArrFromObjKeyId(serviceCartItems);

        // готовим фильтр
        const objFilter = {
            id: undefined,
            datetime: seanceDate,
            services: serviceCartItems,
            term,
            company_id,
            tgid ,
        };

        fetchEmployees(objFilter);

    }, [term]); // венуть зависимость update
// console.log("LAD" , sLoading);
    if(empty){
        return (<>{emptyContent}</>)
    }
    if (eLoading) {
        return (
            <Card sx={{ minWidth: 275 }} elevation={0}>
                <CardHeader
                    avatar={
                        <Skeleton
                            animation="wave"
                            variant="circular"
                            width={40}
                            height={40}
                        />
                    }
                    title={
                        <Skeleton
                            animation="wave"
                            width="80%"
                            height={20}
                            variant="rounded"
                        />
                    }
                    subheader={
                        <List sx={{ padding: 0 }} dense>
                            <ListItem sx={{ padding: 0 }}>
                                <ListItemText
                                    primary={
                                        <Skeleton
                                            animation="wave"
                                            width="70%"
                                            height={15}
                                            variant="rounded"
                                        />
                                    }
                                    secondary={
                                        <Skeleton
                                            animation="wave"
                                            height={15}
                                            width="40%"
                                        />
                                    }
                                />
                            </ListItem>
                        </List>
                    }
                />
                <CardContent sx={{ paddingTop: "6px" }}>
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="100%"
                        height={8}
                    />
                    <Grid container spacing={2} sx={{ paddingTop: "6px" }}>
                        {Array.from(Array(4)).map((_, index) => (
                            <Grid item xs={3} sm={3} md={3} key={index}>
                                <Skeleton
                                    variant="rectangular"
                                    width="80%"
                                    height={20}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </CardContent>
            </Card>
        );
    }

    if (eError) {
        return <ErrorIndicator />;
    }

    return (
        <Box>
            <Stack>
                <EmployeeList
                    employees={employees}
                    fetchSeances={fetchBookSeances}
                    seances={seances}
                    seanceDate={seanceDate}
                    currentEmpId={currentEmpId}
                    addToCart={employeeAddedToCart}
                    setCurrentTerm={setCurrentTerm}
                    term={term}
                    sLoading={sLoading}
                    objFilter={{
                        id: undefined,
                        datetime: seanceDate,
                        services: serviceCart.serviceCartItems,
                        term,
                        company_id,
                        tgid ,
                    }}
                />

            </Stack>
        </Box>
    );
};

const SkeletonStaffCard = () => {
    return (
        <>
            <Card sx={{ minWidth: 275 }} elevation={0}>
        <CardHeader
            avatar={
                <Skeleton
                    animation="wave"
                    variant="circular"
                    width={40}
                    height={40}
                />
            }
            title={
                <Skeleton
                    animation="wave"
                    width="80%"
                    height={20}
                    variant="rounded"
                />
            }
            subheader={
                <List sx={{ padding: 0 }} dense>
                    <ListItem sx={{ padding: 0 }}>
                        <ListItemText
                            primary={
                                <Skeleton
                                    animation="wave"
                                    width="70%"
                                    height={15}
                                    variant="rounded"
                                />
                            }
                            secondary={
                                <Skeleton
                                    animation="wave"
                                    height={15}
                                    width="40%"
                                />
                            }
                        />
                    </ListItem>
                </List>
            }
        />
        <CardContent sx={{ paddingTop: "6px" }}>
            <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height={8}
            />
            <Grid container spacing={2} sx={{ paddingTop: "6px" }}>
                {Array.from(Array(4)).map((_, index) => (
                    <Grid item xs={3} sm={3} md={3} key={index}>
                        <Skeleton
                            variant="rectangular"
                            width="80%"
                            height={20}
                        />
                    </Grid>
                ))}
            </Grid>
        </CardContent>
    </Card>
        </>
    );
}

const mapStateToProps = (state) => {
    // console.log('mapStateToProps' , state);
    const {
        employeeList,
        seancesList,
        serviceCart,
        serviceCart: { seanceDate },
        term: { term, update },
        bookform: { bookform },
        tguserdata ,
    } = state;
    return {
        employees: employeeList.employees,
        eLoading: employeeList.loading,
        eError: employeeList.error,
        seances: seancesList.seances,
        sLoading: seancesList.loading,
        sError: seancesList.error,
        currentEmpId: employeeList.current,
        serviceCart,
        seanceDate,
        term,
        update,
        company_id: bookform.data.company_id,
        tgid: tguserdata.user ? tguserdata.user.id : "" ,
    };
};

const mapDispatchToProps = (dispatch, { clientstoreService }) => {
    //const  = ownProps;
    return {
        fetchEmployees: fetchEmployees(clientstoreService, dispatch),
        fetchBookSeances: fetchBookSeancesEmployees(clientstoreService, dispatch) ,
        employeeAddedToCart: (params) => dispatch(employeeAddedToCart(params)),
        // fetchSeances: fetchEmployeeSeances(clientstoreService , dispatch),
        setCurrentTerm: (term) => dispatch(setCurrentTerm(term)),
    };
};

export default withClientstoreService()(
    connect(mapStateToProps, mapDispatchToProps)(EmployeeListContainer)
);
