import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { dateFormater } from '../../utils/date-formater';
import { isObjEmpty } from '../../utils';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import MySpinner from "../spinner";
import SnackbarNotify from "../snackbar-notify";
import Stack from "@mui/material/Stack";

const tg = window.Telegram.WebApp;

// Проверка на включенный сторадж
let storageSupported = false;
try {
    storageSupported = (window.localStorage && true);
} catch (e) {}

function tgAlert(message , title="Результат"){
    tg.showPopup({title: title , message: message , buttons: [{ type: "close" ,
            text: "Закрыть"}]});
}

const PreFinalDisplay = ({ infoCart,
                             reduxCartClear ,
                             bookRec ,
                             recordLoading ,
                             recordError ,
                             snackBarClose ,
                             final , phone}) => {
    // console.log("PreFinalDisplay PHONE >>>" , phone);
    const activity = useSelector((state) => state.activity);
    const { serviceCartItems, employeeCartItems, orderTotal, seanceDate } = infoCart;
    const master = employeeCartItems.master;
    let strSeanceTime = '';
    let strSeanse = '';


    const popup = {title: "Осталось чуть-чуть..." ,
        message: "Укажите ваш телефон, чтобы завершить запись" , buttons: [{id: 2 , type: "default" ,
        text: "Указать телефон"}]
    };

    const callbackRequestContact = (res) => {
        if(res){
            bookRec();
        }else{
            tgAlert("Без телефона мы не сможем завершить запись" , "Предупреждение");
        }
    }
    const handleClickPopUp = (id_btn) => {
        // const type = typeof id_btn;
        // tgAlert(`ID ${type} = ${id_btn}` , "Предупреждение");
        if(parseInt(id_btn)===2){
            tg.requestContact(callbackRequestContact);
        }else{
            tgAlert("Без телефона мы не сможем завершить запись" , "Предупреждение");
        }
        // if(id_btn===2)tg.requestContact(callbackRequestContact);
    }
    /**
     *
     * @type {(function(): void)|*}
     * final = true если запись прошла успешно поэтому кнопке надоназначить функцию закрытия вебаппа
     * phone = если у нас есть телефон для текущего пользователя ТГ , если он есть тогда не запрашивать телефон повторно
     */
    const onClickTgButton = useCallback(() => {

        if(final){
            tg.close();
        }else{
            if(phone === 'None' || phone === null || phone === undefined || phone === ""){
                tg.showPopup( popup , handleClickPopUp );
            }else{
                bookRec();

            }
        }
    } , [final , phone])


    // const onClickTgButton = () => {
    //     if(final){
    //         tg.close();
    //     }else{
    //         tg.showPopup( popup , handleClickPopUp );
    //     }
    // };

    // tg.MainButton.onClick(onClickTgButton);

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onClickTgButton)
        return () => {
            tg.offEvent('mainButtonClicked', onClickTgButton)
        }
    }, [onClickTgButton])

    useEffect(() => {
        if (storageSupported) {
            localStorage.clear();
        }
    }, []);

    useEffect(() => {
        if(final)reduxCartClear();
        tg.MainButton.text = final? "Закрыть" : 'Завершить запись';
        tg.MainButton.show();

    }, [final]);

    if (!isObjEmpty(seanceDate)) {
        const sDate = dateFormater(seanceDate.date, 'DAY_MON');
        const seanceWeekDay = dateFormater(seanceDate.date, 'LONGDAY');
        strSeanceTime = `${seanceWeekDay} в ${seanceDate.time}`;
        strSeanse = `${sDate} , ${strSeanceTime}`;
    }

    return (
        <Box
            sx={{
                width: '100%',
                bgcolor: 'background.paper',

                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {recordLoading ?<MySpinner />:null}
            {recordError ? <SnackbarNotify variant="error" boolOpen={true} message={recordError} setClose={snackBarClose}/> : null}
            {final && <SnackbarNotify variant="success" boolOpen={true} message="Вы успешно записаны" setClose={snackBarClose}/>}
            <Box sx={{ my: 3, mx: 2 }}>
                <Grid container alignItems='center'>
                    <Grid item xs></Grid>
                    <Grid item>
                        <Stack>
                            <Typography gutterBottom variant='h5' component='div'>
                                Ваша запись {activity.id && 'на групповое занятие'}
                            </Typography>
                            {!(phone === 'None' && phone === null) &&
                                <Typography color='text.secondary' variant='body2'>
                                    На телефон: {phone}
                                </Typography>
                            }

                        </Stack>

                    </Grid>
                    <Grid item xs></Grid>
                </Grid>

                {activity.id ? (
                    <>
                        <Typography color='text.secondary' variant='body2'>
                            Название: {activity.service.title}
                        </Typography>
                        <Divider sx={{ borderStyle: 'dashed', margin: '5px' }} />
                        <Typography color='text.secondary' variant='body2'>
                            Тренер: {activity.staff.name}
                        </Typography>
                        <Divider sx={{ borderStyle: 'dashed', margin: '5px' }} />
                        <Typography color='text.secondary' variant='body2'>
                            Дата: {activity.date_activity}
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography color='text.secondary' variant='body2'>
                            {strSeanse}
                        </Typography>
                        <Divider sx={{ borderStyle: 'dashed', margin: '5px' }} />
                        <Typography color='text.secondary' variant='body2'>
                            к мастеру: {master}
                        </Typography>

                        <ul sx={{ listStyleType: 'none' }}>
                            {serviceCartItems.map((item) => {
                                return (
                                    <li>
                                        <Grid container alignItems='center'>
                                            <Grid item xs>
                                                <Typography color='text.secondary' variant='body2'>
                                                    {item.title}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography color='text.secondary' variant='body2'>
                                                    {item.total} руб.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </li>
                                );
                            })}
                        </ul>
                    </>
                )}
            </Box>
            <Divider variant='middle' sx={{ borderStyle: 'dashed' }} />
            <Box sx={{ m: 2 }}>
                <Grid container alignItems='center'>
                    <Grid item xs>
                        <Typography gutterBottom variant='h5' component='div'>
                            Итого:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography gutterBottom variant='h6' component='div'>
                            {orderTotal} руб.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default PreFinalDisplay;
