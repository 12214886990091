import React , {useState , useEffect} from "react";
import {Card, CardBody, CardTitle} from "reactstrap";
import {withClientstoreService} from "../hoc";
import ElevateAppBar from '../navbar/navbar';
import { useLocation } from "react-router";
import SnackbarNotify from "../snackbar-notify";

import { Route , Routes , useSearchParams } from "react-router-dom";

import {MainPage, SelectServicePage, SelectEmployeePage, SelectEmployeePageId, TimetablePage, FinalDisplayPage , FormPhonePage} from '../pages';
import Bookform from "../bookform";
import CircularProgress from "@mui/material/CircularProgress";
import SkeletonMainPage from "../skeletons/SkeletonMainPage";
import {Unstable_TrapFocus} from "@mui/material";
import SmsCode from "../sms-code";
import MoveRecordPage from "../pages/move-record-page";
import FormGroupsPage from "../pages/form-groups-page";
import FormGroupsPageId from "../pages/form-groups-page-id";
import SelectRecordType from "../pages/select-record-type";
import BottomLinkNotifio from "../bottom-link-notifio";
import TgUserData from "../tg-user-data/tg-user-data";

const tg = window.Telegram.WebApp;
const userData = tg.initDataUnsafe;
const versionBot = tg.version;

// const _mockFormId = '2VYXzgfRkFKo2GW1pCxTt1';
// typeAct = это тип дейсствия который надо выполнить если передан recordId , передается только при повторе записи reorder
// по умолчанию идет book , если передается просто параметр recordId то typeAct = move
const AppRoutes = ({formId , reset , recordId , typeAct , phone }) => {
    const location = useLocation();
   // const {formId} = props;

    // ===== убираем баг =====
    // полуаем bool loading из компонента и если идет загрузка bookform здесь выкидываем колесо крутящиеся
    const [bookFormLoading , setBookFormLoading ] = useState(true);
    const [filialsShow , setFilials] = useState(false);
    const [staffName , setStaffName] = useState("Специалисты");

    const handleLoading = (loading) => {
        setBookFormLoading(loading);
       // console.log("=>>> " , loading);
    }

    const handleStaffName = (staff_name) => {
        setStaffName(staff_name);
    }
    const handleFilials = (bool) => {
        setFilials(bool);
        // console.log("=>>> " , loading);
    }
    // =======================

   // console.log("bookFormLoading" );



    return (
        <React.Fragment>
        <TgUserData user={userData} version={versionBot}/>
        <Bookform
            formId={formId}
            recordId={recordId}
            typeAction={typeAct}
            uPhone={phone}
            getLoading={handleLoading}
            filialsShow={handleFilials}
            getStaffName={handleStaffName}
            clearRedux={reset}
        />
            {bookFormLoading && <SkeletonMainPage />}
            { location.pathname === '/' || location.pathname === '/select-record-type'? null: <ElevateAppBar location={location} staffName={staffName}/>}
            {!bookFormLoading &&
                <Routes>
                <Route
                path="/"
                element={
                    <MainPage formId={formId}
                                   filials={filialsShow}
                    />
                }

                />
                <Route
                path="/services"
                element={ <SelectServicePage /> }
                />
                <Route
                path="/employees"
                element={ <SelectEmployeePage /> }
                />
                <Route
                path="/employees/:id"
                element={ <SelectEmployeePageId formId={formId}/> }
                />
                <Route
                path="/timetable"
                element={ <TimetablePage /> }
                />
                <Route
                path="/yourphone"
                element={ <FormPhonePage /> }
                />
                <Route
                    path="/moverecord"
                    element={ <MoveRecordPage /> }
                />
                <Route
                path="/success"
                element={ <FinalDisplayPage /> }
                />
                <Route
                  path="/smsverification"
                  element={ <SmsCode /> }
                />
                <Route
                  path="/form-groups"
                  element={ <FormGroupsPage /> }
                />
                <Route
                  path="/form-groups/info"
                  element={ <FormGroupsPageId /> }
                />
                <Route
                  path="/select-record-type"
                  element={ <SelectRecordType /> }
                />

                </Routes>
            }

        </React.Fragment>

    );
}

const App = ({ clientstoreService}) => {
    //console.log( clientstoreService.getCurrentOrder());
    const [clearRedux , setClearRedux] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    // Проверка на включенный сторадж
    let storageSupported = false;

    try {
        storageSupported = (window.localStorage && true);
    } catch (e) {}


    // console.log("storage" , storageSupported);


    let _formId = searchParams.get("shop");
    let _recordId = searchParams.get("record_id");
    let _type = searchParams.get("type") || 'move';
    let _phone = searchParams.get("phone");
    // console.log("TYPE" , _type);



    console.log("PHONE >>>" , _phone);
    if (storageSupported)
    {
        // если сторадж включен
        const localShop =  localStorage.getItem('shop');
        // console.log("localShop" , localShop);
        if(_formId === null && localShop ){
            _formId = localShop ;
        }else if( _formId && localShop === null){
            localStorage.setItem('shop', _formId );
        }else if(_formId && localShop){
            if(_formId !== localShop){
                // localStorage.clear();
                localStorage.setItem('shop', _formId );
                setClearRedux(true);

            }
        }

    }



    // console.log("local" , localShop);



    // console.log(localStorage.getItem('shop'));
    // if(_formId)localStorage.setItem('shop', _formId );


    // let _formId;
    // if(searchParams.get("shop")){
    //     // const _formId = searchParams.get("shop");
    //     _formId = searchParams.get("shop");
    // }else{
    //     _formId = _mockFormId;
    // }




     // console.log(searchParams.get("shop"));

    useEffect(() => {
        const tg = window.Telegram.WebApp;
        tg.expand();
        tg.isClosingConfirmationEnabled = true;

    } , []);

    return (

        <main role="main" className="container">
                <AppRoutes
                    formId={_formId}
                    reset={clearRedux}
                    recordId={_recordId}
                    typeAct={_type}
                    phone={_phone}
                />


        </main>
    );


}

export default withClientstoreService()(App);