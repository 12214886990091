import React from "react";
import {FinalDisplay, PreFinalDisplay} from "../final-display";




const FinalDisplayPage = ({prefinal}) => {

    return(
        <React.Fragment>
            {prefinal && <PreFinalDisplay />}
            {prefinal !== true &&
                <FinalDisplay  />
            }

        </React.Fragment>
    );
}

export default FinalDisplayPage;